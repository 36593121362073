import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) { }

  sendMessage(data) {
    return new Promise((resolve, reject) => {
        this.http.post('http://shiannedyges.com/phpmailer/sendEmail.php', data)
        .subscribe(
          response => resolve(response),
          err => reject(err)
        );
    });
  }
}
