import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PortfolioPiece } from '../../domain/portfolio-piece';
import { ViewPieceComponent } from '../view-piece/view-piece.component';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  portfolioPiece: PortfolioPiece[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog) { }

  ngOnInit(): void {
      this.portfolioPiece = [
          {
              id: 0,
              name: "Attraction Tickets",
              subtitle: "Multi-Park Ticket Purchasing Engine",
              imgUrl: "http://shiannedyges.com/new-imgs/portfolio/AT.png",
              description: "Attraction Tickets is a ticket booking engine that allowed users to search and purchase tickets from a variety of theme parks in Orlando, FL at regular price or at a discount with an agreement to attend a resort preview. Unfortunately, this website has remained dormant since I left the position, so there is no live link to preview.",
              tags: ["AngularJS", "Wordpress", "Bootstrap", "UI Bootstrap", "MySQL"],
              liveLink: "",
              subImages: [
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-1.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-2.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-2.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-3.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-3.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-4.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-4.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-5.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-5.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-6.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-6.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-7.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-7.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-8.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-8.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-9.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-9.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-10.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-10.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AT-11.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AT-11.png"
                  }
              ]
          },
          {
              id: 1,
              name: "Book-n-Save",
              subtitle: "Hotel Booking Engine",
              imgUrl: "http://shiannedyges.com/new-imgs/portfolio/BNS.png",
              description: "Book-n-Save is a hotel booking engine that was designed with well known hotel engines, like Expedia and Travelocity, in mind. This website allowed users to search hotel inventory from several different cities and book a hotel stay at regular price, or at a discount with an agreement to attend a resort preview. Unfortunately, this website has remained dormant since I left the position, so there is no live link to preview.",
              tags: ["AngularJS", "Wordpress", "Bootstrap", "UI Bootstrap", "MySQL"],
              liveLink: "",
              subImages: [
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/BNS-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/BNS-1.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/BNS-2.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/BNS-2.png"
                  }
              ]
          },
          {
              id: 2,
              name: "EPCOT Food & Wine Festival",
              subtitle: "Individual Park Ticket Purchasing Engine",
              imgUrl: "http://shiannedyges.com/new-imgs/portfolio/FAW.png",
              description: "This website allowed users to purchase tickets for the EPCOT Food and Wine Festival. Users were able to purchase tickets at regular price or at a discounted price with an agreement to attend a resort preview, by entering a promo code.",
              tags: ["AngularJS", "Wordpress", "Bootstrap", "UI Bootstrap", "MySQL"],
              liveLink: "https://fb.magicalgetaway.com/foodandwine",
              subImages: [
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FAW-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FAW-1.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FAW-2.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FAW-2.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FAW-3.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FAW-3.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FAW-4.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FAW-4.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FAW-5.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FAW-5.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FAW-6.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FAW-6.png"
                  },
              ]
          },
          {
              id: 3,
              name: "EPCOT International Festival of the Arts",
              subtitle: "Individual Park Ticket Purchasing Engine",
              imgUrl: "http://shiannedyges.com/new-imgs/portfolio/IFOA.png",
              description: "This website allowed users to purchase tickets for the EPCOT International Festival of the Arts. Users were able to purchase tickets at regular price or at a discounted price with an agreement to attend a resort preview, by entering a promo code. This website was developed in Spanish as well. Unfortunately, this website has remained dormant since I left the position, so there is no live link to preview.",
              tags: ["AngularJS", "Wordpress", "Bootstrap", "UI Bootstrap", "MySQL"],
              liveLink: "",
              subImages: [
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-2.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-2.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-1.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-3.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-3.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-4.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-4.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-5.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-5.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-6.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-6.png"
                  },{
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-2.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-2.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-1.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-3.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-3.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-4.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-4.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-5.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-5.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-6.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/IFOA-ES-6.png"
                  }
              ]
          },
          {
              id: 4,
              name: "Get A Free Night",
              subtitle: "Lead Collection",
              imgUrl: "http://shiannedyges.com/new-imgs/portfolio/LC.png",
              description: "This application allowed the company to collect leads. Users were able to claim a free night at select hotels if they attended a resort preview and purchased one night at a discounted price. Users had to be qualified and answer questions before they were able to select a hotel and check out.",
              tags: ["AngularJS", "Wordpress", "Bootstrap", "UI Bootstrap", "MySQL"],
              liveLink: "https://fb.magicalgetaway.com/leadCollection/#/claim",
              subImages: [
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/LC-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/LC-1.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/LC-2.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/LC-2.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/LC-3.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/LC-3.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/LC-4.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/LC-4.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/LC-5.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/LC-5.png"
                  },
              ]
          },
          {
              id: 5,
              name: "Agent Portal",
              subtitle: "In-House Agent Booking Engine",
              imgUrl: "http://shiannedyges.com/new-imgs/portfolio/AP.png",
              description: "This is an in-house application that was built for call center agents. It allows them to easily search for hotel and attraction inventory, such as theme park tickets and vouchers. They are able to see all of the necessary hotel details to provide customers with accurate information and sort through the list using several different parameters. The agents are able to easily distinguish between regular prices and the discounted prices for taking a timeshare tour and let customers know how much they will save by touring. They are then able to collect customer information and enter it into the system. I believe this application is still in use, so I cannot provide a live link.",
              tags: ["AngularJS", "Wordpress", "Bootstrap", "UI Bootstrap", "MySQL"],
              liveLink: "",
              subImages: [
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-1.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-2.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-2.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-3.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-3.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-4.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-4.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-5.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-5.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-6.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-6.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-7.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-7.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-8.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-8.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-9.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-9.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/AP-10.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/AP-10.png"
                  }
              ]
          },
          {
              id: 6,
              name: "Football Pool",
              subtitle: "Personal Portfolio",
              imgUrl: "http://shiannedyges.com/new-imgs/portfolio/FP.png",
              description: "This application is a project that I built for my father and his friends. It allows users to select the teams they think will win for all games to be played in the current week. The website has a selection cut-off date, which is Thursday of every week before the first game. Selection reopens the following Tuesday morning. Users are also able to view the picks that other users on the application have submitted for the current week or any previous week.",
              tags: ["Angular", "Angular Material", "REST API", "SportsData.io", "PHP", "MySQL"],
              liveLink: "http://shiannedyges.com/football-pool/",
              subImages: [
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FP-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FP-1.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FP-2.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FP-2.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FP-3.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FP-3.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FP-4.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FP-4.png"
                  },
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/FP-5.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/FP-5.png"
                  }              ]
          },
          {
              id: 7,
              name: "Queen Ri Art",
              subtitle: "Personal Portfolio",
              imgUrl: "http://shiannedyges.com/new-imgs/portfolio/QR.png",
              description: "This project is a portfolio website. Ritannia Grant is a graphic designer that needed a portfolio that was true to her personality, and she designed it as such. The website displays some of the art work, in various media forms, that she has created.",
              tags: ["Angular", "Angular Material", "PHP", "PHPMailer", "ngx-page-scroll"],
              liveLink: "http://queenriart.com",
              subImages: [
                  {
                      image: "http://shiannedyges.com/new-imgs/portfolio/QR-1.png",
                      thumbImage: "http://shiannedyges.com/new-imgs/portfolio/QR-1.png"
                  }
              ]
          }
      ];
  }

  openPiece(piece) {
      console.log("Selected Piece:", piece);
      const dialogRef = this.dialog.open(ViewPieceComponent, {
          width: '700px',
          data: {piece},
      });
      // this.router.navigate(['/view-piece', piece.id], { relativeTo: this.route });
  }

}
