<div style="background-color: #FFF; border-top: 3px solid #fed9b7; width: calc(100% - 2rem); padding: 3rem 1rem 4rem;">
    <div style="display: flex; width: 250px; margin: 0 auto;">
        <a href="Shianne-Dyges-Resume.pdf" target="_blank" style="text-decoration: none;">
            <mat-icon style="margin-top: 20px; color: #0081a7;" aria-hidden="false" aria-label="Example home icon">download</mat-icon>
        </a>
        <p style="font-family: 'Open Sans', sans-serif; font-size: 19px; font-weight: 700;">
            <a href="Shianne-Dyges-Resume.pdf" target="_blank" style="text-decoration: none; color: #000;">Download Resume</a>
        </p>
    </div>

    <h1 style="font-family: 'Bebas Neue', cursive; text-align: center; margin-top: 40px; letter-spacing: 5px;">Education</h1>

    <div class="education-wrapper">
        <div class="education-item">
            <img style="display: block; margin: 0 auto;" width="150" src="http://shiannedyges.com/new-imgs/UCF.png">
            <p style="text-align: center; font-size: 20px; margin-bottom: .5rem;">University of Central Florida</p>
            <p style="text-align: center; margin: .5rem 0; font-weight: 500;">B.A. Digital Media - Web Design</p>
            <p style="text-align: center; font-size: 12px; margin: .5rem 0 0;">2013-2017</p>
        </div>
        <div class="education-item">
            <img style="display: block; margin: 0 auto;" width="150" src="http://shiannedyges.com/new-imgs/VC.png">
            <p style="text-align: center; font-size: 20px; margin-bottom: .5rem;">Valencia College</p>
            <p style="text-align: center; margin: .5rem 0; font-weight: 500;">General A.A.</p>
            <p style="text-align: center; font-size: 12px; margin: .5rem 0 0;">2011-2013</p>
        </div>
    </div>

    <h1 style="font-family: 'Bebas Neue', cursive; text-align: center; margin-top: 40px; letter-spacing: 5px;">I've been working professionally for...</h1>

    <div class="wrapper">
        <div class="time-item">
            <p #counter [options]="{duration: 5}" class="time" [countUp]="years">0</p>
            <h1 class="time-heading">Years</h1>
        </div>
        <div class="time-item">
            <p #counter [options]="{duration: 5}" class="time" [countUp]="months">0</p>
            <h1 class="time-heading">Months</h1>
        </div>
        <div class="time-item">
            <p #counter [options]="{duration: 5}" class="time" [countUp]="weeks">0</p>
            <h1 class="time-heading">Weeks</h1>
        </div>
        <div class="time-item">
            <p #counter [options]="{duration: 5}" class="time" [countUp]="days">0</p>
            <h1 class="time-heading">Days</h1>
        </div>
    </div>

    <h1 style="font-family: 'Bebas Neue', cursive; text-align: center; margin-top: 40px; letter-spacing: 5px;">Skills</h1>

    <div class="skills-wrapper">
        <div class="skills-item">
            <p class="skills-percentage">95%</p>
            <canvas  #chart baseChart class="skills-chart"
                    [data]="htmlChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
            <p class="skills-title">HTML</p>
        </div>
        <div class="skills-item">
            <p class="skills-percentage">93%</p>
            <canvas  #chart baseChart class="skills-chart"
                    [data]="cssChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
            <p class="skills-title">CSS</p>
        </div>
        <div class="skills-item">
            <p class="skills-percentage">92%</p>
            <canvas  #chart baseChart class="skills-chart"
                    [data]="javascriptChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
            <p class="skills-title">JAVASCRIPT</p>
        </div>
        <div class="skills-item">
            <p class="skills-percentage">78%</p>
            <canvas  #chart baseChart class="skills-chart"
                    [data]="phpChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
            <p class="skills-title">PHP</p>
        </div>
        <div class="skills-item">
            <p class="skills-percentage">97%</p>
            <canvas  #chart baseChart class="skills-chart"
                    [data]="angularChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
            <p class="skills-title">ANGULAR</p>
        </div>
        <div class="skills-item">
            <p class="skills-percentage">50%</p>
            <canvas  #chart baseChart class="skills-chart"
                    [data]="reactChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
            <p class="skills-title">REACT</p>
        </div>
        <div class="skills-item">
            <p class="skills-percentage">87%</p>
            <canvas  #chart baseChart class="skills-chart"
                    [data]="scrumChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
            <p class="skills-title">SCRUM</p>
        </div>
        <div class="skills-item">
            <p class="skills-percentage">90%</p>
            <canvas  #chart baseChart class="skills-chart"
                    [data]="figmaChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
            <p class="skills-title">FIGMA</p>
        </div>
        <!-- <div style="display: block">
            <p>90%</p>
            <canvas baseChart
                    [data]="doughnutChartData"
                    [type]="doughnutChartType"
                    [options]="doughnutChartOptions"></canvas>
        </div> -->
    </div>

    <h1 style="font-family: 'Bebas Neue', cursive; text-align: center; margin-top: 40px; letter-spacing: 5px;">But wait, there's more!</h1>

    <div style="width: 1000px; max-width: 100%; height: auto; margin: 1rem auto 4rem;">
        <ng-image-slider class="resume-slider" [images]="imageObject"
                         [infinite]="true"
                         [autoSlide]="{interval: 2, stopOnHover: false}"
                         [showArrow]="false"
                         [imagePopup]="false"
                         [imageSize]="{height: '125px'}" #nav></ng-image-slider>
    </div>

    <h1 style="font-family: 'Bebas Neue', cursive; text-align: center; margin-top: 40px; letter-spacing: 5px;">Experience</h1>

    <div class="wrapper experience-wrapper">
        <div class="experience-item">
            <mat-accordion>
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <img width="150" src="http://shiannedyges.com/new-imgs/wg-logo.png">
                        </mat-panel-title>
                        <mat-panel-description style="font-family: 'Open Sans', sans-serif; font-weight: 700;">
                            Software Engineer
                        </mat-panel-description>
                        <mat-panel-description style="font-family: 'Open Sans', sans-serif; font-size: 12px;">
                            2019-Present
                            <mat-icon style="color: #000; margin-left: auto;">keyboard_arrow_down</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <!-- <p>This is the primary content of the panel.</p> -->

                    <ul>
                        <li>Developed websites for new brands, allowing for timeshare sales by way of hotel and ticket booking engines</li>
                        <li>Developed in house back end CMS’s for various tickets and offers</li>
                        <li>Developed HTML emails and sent out email campaignes using Mailchimp</li>
                        <li>Set up tracking and reporting in Google Analytics</li>
                    </ul>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="experience-item">
            <mat-accordion>
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <img width="75" src="http://shiannedyges.com/new-imgs/wg-logo-small.png">
                            <img style="width: 75px; height: 55px; margin-top: 5px;" src="http://shiannedyges.com/new-imgs/hc-logo.png">
                        </mat-panel-title>
                        <mat-panel-description style="font-family: 'Open Sans', sans-serif; font-weight: 700;">
                            Web Developer
                        </mat-panel-description>
                        <mat-panel-description style="font-family: 'Open Sans', sans-serif; font-size: 12px;">
                            2017-2019
                            <mat-icon style="color: #000; margin-left: auto;">keyboard_arrow_down</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <!-- <p>This is the primary content of the panel.</p> -->

                    <ul>
                        <li>Developed applications for the business using Angular, materialize and jQuery</li>
                        <li>Workerd with UI/UX designers to help create and implement best design practices</li>
                        <li>Assisted in building reusable code for the team</li>
                        <li>Learned and implemented JSP and JSTL</li>
                        <li>Wrote and edited small pieces of Java code</li>
                    </ul>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>