import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PortfolioPiece } from '../../domain/portfolio-piece';

@Component({
  selector: 'app-view-piece',
  templateUrl: './view-piece.component.html',
  styleUrls: ['./view-piece.component.css']
})
export class ViewPieceComponent implements OnInit {

  imageObject: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<any>) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
      console.log("Piece:", this.data);
      this.imageObject = this.data.piece.subImages;
  }

  closeModal() {
      this.dialog.closeAll();
  }

}
