<mat-sidenav-container class="sidenav-container">
    <mat-sidenav
        #drawer
        class="sidenav"
        fixedInViewport="true"
        [ngClass]="{ hidden: !(isHandset$ | async) }"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="!(isHandset$ | async)">

        <!-- Logo will go here -->
        <!-- <p class="mobile-header">Shianne Dyges</p> -->
        <img class="nav-logo-mobile" src="http://shiannedyges.com/new-imgs/my-logo.png" width="200">
        <!-- Mobile Nav -->
        <mat-nav-list>
            <a pageScroll class="nav-link" mat-list-item (click)="scrollTo('home')">About</a>
            <a pageScroll class="nav-link" mat-list-item (click)="scrollTo('resume')">Resume</a>
            <a pageScroll class="nav-link" mat-list-item (click)="scrollTo('portfolio')">Portfolio</a>
            <a pageScroll class="nav-link" mat-list-item (click)="scrollTo('contact')">Contact</a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <!-- Logo -->
            <img class="nav-logo-desktop" src="http://shiannedyges.com/new-imgs/my-logo.png" width="200">
            <!-- Spacer -->
            <span class="menu-spacer"></span>
            <!-- Desktop Nav -->
            <div class="desktop-nav" fxShow="true" fxHide.lt-md>
                <!-- The following menu items will be hidden on both SM and XS screen sizes -->
                <a pageScroll class="nav-link" mat-button (click)="scrollTo('home')">About</a>
                <a pageScroll class="nav-link" mat-button (click)="scrollTo('resume')">Resume</a>
                <a pageScroll class="nav-link" mat-button (click)="scrollTo('portfolio')">Portfolio</a>
                <a pageScroll class="nav-link" mat-button (click)="scrollTo('contact')">Contact</a>
            </div>
            <!-- <span class="menu-spacer"></span> -->
            <!-- Logo -->
            <!-- <img src="http://shiannedyges.com/ritannia/white-logo.png"> -->
            <!-- Mobile Menu Button -->
            <button
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">

                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
        </mat-toolbar>
        <!-- Add Content Here -->
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>