import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './components/base/base.component';
import { ViewPieceComponent } from './components/view-piece/view-piece.component';

const routes: Routes = [
    { path: '', component: BaseComponent },
    { path: 'view-piece/:id', component: ViewPieceComponent }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
