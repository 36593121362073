<div style="width: calc(100% - 2rem); padding: 1rem 1rem 0.5rem; background-color: #0081a7;">
    <div class="footer-nav">
        <div class="footer-nav-link-container">
            <a class="footer-nav-link" (click)="scrollTo('home')">About</a>
        </div>
        <div class="footer-nav-link-container">
            <a class="footer-nav-link" (click)="scrollTo('resume')">Resume</a>
        </div>
        <div class="footer-nav-link-container">
            <a class="footer-nav-link" (click)="scrollTo('portfolio')">Portfolio</a>
        </div>
        <div class="footer-nav-link-container">
            <a class="footer-nav-link" (click)="scrollTo('contact')">Contact</a>
        </div>
    </div>

    <div class="other-contact-item">
        <div class="other-contact-social-icon">
            <div style="width: 30px; height: 30px; margin: 0 auto;">
                <a href="https://www.linkedin.com/in/shiannedyges" target="_blank">
                    <img width="30" src="http://shiannedyges.com/new-imgs/LI.png">
                </a>
            </div>
        </div>
        <div class="other-contact-social-icon">
            <div style="width: 30px; height: 30px; margin: 0 auto;">
                <a href="https://www.linkedin.com/in/shiannedyges" target="_blank">
                    <img width="30" src="http://shiannedyges.com/new-imgs/GH.png">
                </a>
            </div>
        </div>
        <div class="other-contact-social-icon">
            <div style="width: 30px; height: 30px; margin: 0 auto;">
                <a href="https://www.linkedin.com/in/shiannedyges" target="_blank">
                    <img width="30" src="http://shiannedyges.com/new-imgs/IG.png">
                </a>
            </div>
        </div>
    </div>

    <p style="text-align: center; margin-bottom: 0; font-size: 12px;">©Copyright ShianneDyges 2022</p>
</div>