<div style="height: auto; width: calc(100% - 2rem); padding: 100px 1rem 1rem; background-image: url('http://shiannedyges.com/new-imgs/main-background.png'); background-repeat: no-repeat; background-size: cover;">
    <div style="width: 1000px; max-width: 100%; margin: 0 auto;">
        <p style="font-family: 'Open Sans', sans-serif; text-align: center; margin-bottom: 0.25rem; font-size: 20px;">Hi! I'm</p>
        <!-- <p class="typed-element" style="text-align: center;">Shi</p> -->
        <div style="text-align: center; margin-bottom: 20px; font-family: 'Bebas Neue', cursive; font-size: 35px;">
            <span class="typed-element"></span>
        </div>

        <div style="margin: 0 auto; width: 400px; max-width: 100%;">
            <img src="http://shiannedyges.com/new-imgs/me.png" width="400" style="max-width: 100%;">
        </div>

        <p style="text-align: center; font-family: 'Open Sans', sans-serif; font-size: 19px; font-weight: 500; line-height: 1.5; margin-bottom: 0;">
            My name is Shianne Dyges. I am a Software Engineer with 4+ years of experience. I am from Fort Lauderdale, FL but moved to Orlando, FL in 2011 to attend the University of Central Florida, where I obtained my BA in Digital Media with a focus in Web Design and Development. I specialize in building Angular applications from beginning to end. I love what I do and I have a plethora of skills, but I am always interested in learning and growing in my field. Keep scrolling and take a look at my portfolio!
        </p>
    </div>
</div>