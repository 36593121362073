import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { ChartData, ChartType, Chart } from 'chart.js';
import { SubImage } from '../../domain/sub-image';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {

  @ViewChild('counter') counter: ElementRef;
  @ViewChild('chart') chart: ElementRef;

  public htmlChartData: ChartData<'doughnut'> = { datasets: [{ data: [ 95, 5 ] }] };
  public cssChartData: ChartData<'doughnut'> = { datasets: [{ data: [ 93, 8 ] }] };
  public javascriptChartData: ChartData<'doughnut'> = { datasets: [{ data: [ 92, 17 ] }] };
  public phpChartData: ChartData<'doughnut'> = { datasets: [{ data: [ 78, 22 ] }] };
  public angularChartData: ChartData<'doughnut'> = { datasets: [{ data: [ 97, 3 ] }] };
  public reactChartData: ChartData<'doughnut'> = { datasets: [{ data: [ 50, 50 ] }] };
  public scrumChartData: ChartData<'doughnut'> = { datasets: [{ data: [ 87, 13 ] }] };
  public figmaChartData: ChartData<'doughnut'> = { datasets: [{ data: [ 90, 10 ] }] };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions: any = {
      legend: {
        display: false
      },
      options: {

      },
      tooltip: {
          enabled: false,
      },
      backgroundColor: ['#f07167', '#fdfcdc'],
      borderColor: 'transparent',
      hoverBackgroundColor: ['#f07167', '#fdfcdc'],
      hoverBorderColor: 'transparent',
      cutout: 70
  };

  imageObject: SubImage[];
  years: number;
  months: number;
  weeks: number;
  days: number;
  inView: boolean = false;

  constructor() { }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onWindowScroll(event) {
      var countRrect = this.counter.nativeElement.getBoundingClientRect();
      var countElemTop = countRrect.top;
      var countElemBottom = countRrect.bottom;
      // console.log("Top:", elemTop);
      // console.log("Bottom:", elemBottom);
      // console.log("Window Inner Height:", window.innerHeight);
      if ((countElemTop >= 0) && (countElemBottom <= window.innerHeight)) {
          this.countFrom("Jul 15, 2017 12:00:00");
      }else {
          // this.years = 0;
          // this.months = 0;
          // this.weeks = 0;
          // this.days = 0;
      }
      // (countElemTop >= 0) && (countElemBottom <= window.innerHeight) ? this.countFrom("Jul 15, 2017 12:00:00"):null;

      var chartRrect = this.counter.nativeElement.getBoundingClientRect();
      var chartElemTop = chartRrect.top;
      var chartElemBottom = chartRrect.bottom;
      // console.log("Top:", chartElemTop);
      // console.log("Bottom:", chartElemBottom);
      // console.log("Window Inner Height:", window.innerHeight);
      if ((chartElemTop >= 0) && (chartElemBottom <= 325)) {
          if (this.inView) {
              return;
          }
          this.inView = true;
          this.htmlChartData = { datasets: [{ data: [ 95, 5 ] }] };
          this.cssChartData = { datasets: [{ data: [ 93, 8 ] }] };
          this.javascriptChartData = { datasets: [{ data: [ 92, 17 ] }] };
          this.phpChartData = { datasets: [{ data: [ 78, 22 ] }] };
          this.angularChartData = { datasets: [{ data: [ 97, 3 ] }] };
          this.reactChartData = { datasets: [{ data: [ 50, 50 ] }] };
          this.scrumChartData = { datasets: [{ data: [ 87, 13 ] }] };
          this.figmaChartData = { datasets: [{ data: [ 90, 10 ] }] };
      }else {
          this.inView = false;
      }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
      // this.countFrom("Jul 15, 2017 12:00:00");

      this.imageObject = [
          {
              image: "http://shiannedyges.com/images/boot.png",
              thumbImage: "http://shiannedyges.com/images/boot.png"
          },
          {
              image: "http://shiannedyges.com/images/mysql.png",
              thumbImage: "http://shiannedyges.com/images/mysql.png"
          },
          {
              image: "http://shiannedyges.com/images/ajax.png",
              thumbImage: "http://shiannedyges.com/images/ajax.png"
          },
          {
              image: "http://shiannedyges.com/images/github.png",
              thumbImage: "http://shiannedyges.com/images/github.png"
          },
          {
              image: "http://shiannedyges.com/images/sass.png",
              thumbImage: "http://shiannedyges.com/images/sass.png"
          },
          {
              image: "http://shiannedyges.com/images/npm.png",
              thumbImage: "http://shiannedyges.com/images/npm.png"
          },
          {
              image: "http://shiannedyges.com/images/materialize.png",
              thumbImage: "http://shiannedyges.com/images/materialize.png"
          },
          {
              image: "http://shiannedyges.com/images/jquery.png",
              thumbImage: "http://shiannedyges.com/images/jquery.png"
          },
          {
              image: "http://shiannedyges.com/images/ae.png",
              thumbImage: "http://shiannedyges.com/images/ae.png"
          }
      ];
  }

  countFrom(countFromDate) {
      var d1 = new Date(countFromDate);
      var d2 = new Date();

      var years;
          years = (d2.getTime() - d1.getTime()) / (60 * 60 * 1000 * 24) * 1;
          years = years / 365;
      // console.log("Years:", Math.round(years));

      var months;
          months = (d2.getFullYear() - d1.getFullYear()) * 12;
          months -= d1.getMonth();
          months += d2.getMonth();
      // console.log("Months:", Math.round(months));

      var weeks;
          weeks = (d2.getTime() - d1.getTime()) / 1000;
          weeks /= (60 * 60 * 24 * 7);
      // console.log("Weeks:", Math.round(weeks));

      var days;
          days = d2.getTime() - d1.getTime();
          days = days / (1000 * 60 * 60 * 24);
      // console.log("Days:", Math.round(days));

      this.years = Math.round(years);
      this.months = Math.round(months);
      this.weeks = Math.round(weeks);
      this.days = Math.round(days);
  }

}
