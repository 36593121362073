import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private pageScrollService: PageScrollService,
              @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {
  }

  scrollTo(scrollToRoute){
      console.log("Route:", scrollToRoute);

      this.pageScrollService.scroll({
        document: this.document,
        scrollTarget: '#' + scrollToRoute
      });
  }

}
