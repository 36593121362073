<div class="piece-container">
    <div class="piece-slider-container">
        <ng-image-slider class="piece-slider" [images]="imageObject" [imageSize]="{width: '100%', height: '450px', space: 0}" #nav></ng-image-slider>
    </div>
    <div class="piece-details-container">
        <span class="zoom-container">
            <mat-icon class="zoom-icon">zoom_in</mat-icon>
            <span class="zoom-text">Click to Zoom</span>
        </span>
        <span class="piece-title-container">
            <h1 class="piece-title">{{data.piece.name}}</h1>
            <mat-icon class="close-icon" (click)="closeModal()">close</mat-icon>
            <!-- <span style="align-self: center; cursor: pointer; text-transform: uppercase;">Close</span> -->
        </span>

        <p class="piece-subtitle">{{data.piece.subtitle}}</p>
        <hr style="margin: 12px 0; background-color: #c0c0c0;">
        <p class="piece-description">{{data.piece.description}}</p>

        <ul class="piece-tags-container">
            <li class="piece-tag" *ngFor="let tag of data.piece.tags">
                <mat-icon class="piece-tag-icon">add</mat-icon> {{tag}}
            </li>
        </ul>

        <div class="piece-button-container" *ngIf="data.piece.liveLink !== ''">
            <a class="piece-button" mat-raised-button href="{{data.piece.liveLink}}" target="_blank">View Live Site</a>
            <p *ngIf="data.piece.name !== 'Football Pool' && data.piece.name !== 'Queen Ri Art'" class="piece-button-disclaimer">Please do not enter any information on the live site</p>
        </div>
    </div>
</div>