<div style="background-color: #FFF; width: calc(100% - 2rem); padding: 3rem 1rem 4rem;">
    <h1 style="font-family: 'Bebas Neue', cursive; text-align: center; margin-top: 40px; margin-bottom: 40px; letter-spacing: 5px;">Get In Touch</h1>

    <div class="other-contact-wrapper">
        <div class="other-contact-item">
            <mat-icon class="other-contact-icon">email</mat-icon>
            <p class="other-contact-text">webdesignsbyshi@gmail.com</p>
        </div>
        <div class="other-contact-item">
            <mat-icon class="other-contact-icon">phone_iphone</mat-icon>
            <p class="other-contact-text">(754) 422-4538</p>
        </div>
    </div>

    <div *ngIf="showSuccessMessage" style="font-family: 'Bebas Neue', cursive; font-size: 28px; color: #0081a7; text-align: center;">
        <p>Thanks for reaching out! I'll get back to you within 24-48 hours</p>
    </div>

    <form [formGroup]="contactForm" class="contact-form">
      <mat-card-content>
        <mat-form-field class="half-width left">
          <input matInput formControlName="firstName" placeholder="First Name" required>
        </mat-form-field>

        <mat-form-field class="half-width right">
          <input matInput formControlName="lastName" placeholder="Last Name" required>
        </mat-form-field>
        <span *ngIf="showNameError" style="background-color: #FFF4F4; display: block; padding: 1rem; color: #CD3838; margin-bottom: 1rem;">
          Please enter a valid first and last name
        </span>

        <mat-form-field class="full-width">
          <input matInput formControlName="email" placeholder="Email" type="email" required>
        </mat-form-field>
        <span *ngIf="showEmailError" style="background-color: #FFF4F4; display: block; padding: 1rem; color: #CD3838;  margin-bottom: 1rem;">
          Please enter a valid email address
        </span>

        <mat-form-field class="full-width">
          <input matInput formControlName="phone" placeholder="Phone Number" type="text" required>
        </mat-form-field>
        <span *ngIf="showPhoneError" style="background-color: #FFF4F4; display: block; padding: 1rem; color: #CD3838;  margin-bottom: 1rem;">
          Please enter a valid 10 digit phone number
        </span>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Message</mat-label>
            <textarea matInput formControlName="message" placeholder="Ex. Iam looking for..."></textarea>
        </mat-form-field>
    </mat-card-content>
    <span *ngIf="showMessageError" style="background-color: #FFF4F4; display: block; padding: 1rem; color: #CD3838;  margin-bottom: 1rem;">
      Please enter a message
    </span>
    <!-- Error Message -->
    <span *ngIf="showErrorMessage" style="background-color: #FFF4F4; display: block; padding: 1rem; color: #CD3838; border-radius: 4px; margin-top: 15px;">
      Something went wrong. Please try to send your message again
    </span>

      <button [disabled]="showSpinner" (click)="sendEmail()" mat-stroked-button color="accent" class="submit-button">
        <span *ngIf="!showSpinner">Send Message</span>
        <span *ngIf="showSpinner">Sending...</span>
        <div *ngIf="showSpinner" class="lds-ripple"><div></div><div></div></div>
      </button>
    </form>
</div>