import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  showNameError: boolean;
  showEmailError: boolean;
  showPhoneError: boolean;
  showMessageError: boolean;
  showSpinner: boolean;

  constructor(public fb: FormBuilder,
              public messageService: MessageService) {
      this.contactFormSetup();
  }

  ngOnInit(): void {
    this.showSuccessMessage = false;
    this.showErrorMessage = false
    this.showNameError = false;
    this.showEmailError = false;
    this.showPhoneError = false;
    this.showMessageError = false;
  }

  contactFormSetup() {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }

  sendEmail() {
    var emailRegEx = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
    var phoneRegEx = new RegExp('^((\\+91-?)|0)?[0-9]{10}$');

    if(this.contactForm.value.firstName === "" || this.contactForm.value.lastName === "") {
      this.showNameError = true;
    }else {
      this.showNameError = false;
     }

    if (!this.contactForm.value.email.match(emailRegEx)) {
      this.showEmailError = true;
    }else {
      this.showEmailError = false;
    }

    if (!this.contactForm.value.phone.match(phoneRegEx)) {
      this.showPhoneError = true;
    }else {
      this.showPhoneError = false;
    }

    if (this.contactForm.value.message === "") {
      this.showMessageError = true;
    }else {
      this.showMessageError = false;
    }

    if (this.contactForm.value.firstName !== "" && this.contactForm.value.lastName !== "" &&
          this.contactForm.value.email.match(emailRegEx) &&
          this.contactForm.value.phone.match(phoneRegEx) &&
          this.contactForm.value.message !== "") {
        this.showSpinner = true;
        console.log("All Valid!");

        this.messageService.sendMessage(this.contactForm.value)
        .then((serverResponse: any) => {
            console.log('Request Successful! Login Results:', serverResponse);

            this.showSpinner = false;

            if (serverResponse == 'true') {
                this.showSuccessMessage = true;
                this.showErrorMessage = false;

                this.contactForm.reset();

                setTimeout(()=>{
                    this.showSuccessMessage = false;
                }, 5000);
            }
        })
        .catch((serverError: any) => {
            console.error('error in subscribe err', serverError);
            this.showSpinner = false;
            this.showErrorMessage = true;
            this.showSuccessMessage = false;
        });
    }

    console.log("Contact Form:", this.contactForm.value);


    // this.messageService.sendMessage(this.contactForm.value).subscribe((res) => {
    //   console.log("Response:", res);
    // });
  }

}
